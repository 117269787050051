const translations = {
    header: {
        home: 'Tiny ERP',
    },
    button: {
        documentation: 'Visita la documentación'
    },
    system: {
        title: 'Información del sistema',
        tenant: 'Nombre del contrato',
        name: 'Nombre del Bot',
        identifier: 'Identificador de Bot',
        accessKey: 'Llave de acceso',
    },
    integration: {
        title: 'Información de integración',
        ...JSON.parse('{"token":"Token de acesso para a API Tiny","order_status_namespace":"Actualización del estado del pedido: namespace","order_status_template":"Actualización del estado del pedido: nombre del modelo (2 variables)","order_status_return_flow_id":"Actualización del estado del pedido: return flow ID","order_status_return_state_id":"Actualización del estado del pedido: return state ID","generated":{"hook_order_status":"Webhook URL para actualización del estado del pedido"}}'),
        submit: 'Actualizar',
        generated: {
            title: 'Información generada',
            ...JSON.parse('{"hook_order_status":"Webhook URL para actualización del estado del pedido"}'),
            default: "Nada que ver aquí hasta ahora",
        }
    }
};

export default translations;