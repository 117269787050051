import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';

export const Generated = ({data, copy}) => {
    const { t } = useTranslation();

    return (
        <Card className="min-h-18 pa3">
            <div className="overflow-auto">
                <h4 className="f4 mt0 mb3">
                    {t('integration.generated.title')}
                </h4>

                {data.generated
                    ?<div>
                        <div className="mv3">
                            <bds-typo>{t('integration.generated.hook_order_status')}</bds-typo>
                            <Button icon="copy" variant="ghost" onClick={() => copy(data.generated["hook_order_status"])}>{data.generated["hook_order_status"]}</Button>
                        </div>

                    </div>
                    :<p>{t('integration.generated.default')}</p>
                }
            </div>
        </Card>
    );
};

Generated.propTypes = {
};

export default Generated;
