const translations = {
    header: {
        home: 'Tiny ERP',
    },
    button: {
        documentation: 'See the docs'
    },
    system: {
        title: 'System Info',
        tenant: 'Contract Name',
        name: 'Bot Name',
        identifier: 'Bot Identifier',
        accessKey: 'Access Key',
    },
    integration: {
        title: 'Integration information',
        ...JSON.parse('{"token":"Access token for Tiny API","order_status_namespace":"Order status update: namespace","order_status_template":"Order status update: template name (2 variables)","order_status_return_flow_id":"Order status update: return flow ID","order_status_return_state_id":"Order status update: return state ID","generated":{"hook_order_status":"Webhook URL for order status update"}}'),
        submit: 'Update',
        generated: {
            title: 'Generated information',
            ...JSON.parse('{"hook_order_status":"Webhook URL for order status update"}'),
            default: 'Nothing to see here for now'
        }

    }
};

export default translations;