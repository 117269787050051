const translations = {
    header: {
        home: 'Tiny ERP',
    },
    button: {
        documentation: 'Visite a documentação'
    },
    system: {
        title: 'Informações do sistema',
        tenant: 'Nome do Contrato',
        name: 'Nome do Bot',
        identifier: 'Identificador do Bot',
        accessKey: 'Chave de acesso',
    },
    integration: {
        title: 'Informações de integração',
        ...JSON.parse('{"token":"Token de acesso para Tiny API","order_status_namespace":"Atualização de status de pedido: namespace","order_status_template":"Atualização de status de pedido: nome do modelo (com 2 variáveis)","order_status_return_flow_id":"Atualização de status de pedido: ID do fluxo de retorno","order_status_return_state_id":"Atualização de status de pedido: ID do estado de retorno","generated":{"hook_order_status":"URL para o Webhook de atualização de status de pedidos"}}'),
        submit: 'Atualizar',
        generated: {
            title: 'Informações geradas',
            ...JSON.parse('{"hook_order_status":"URL para o Webhook de atualização de status de pedidos"}'),
            default: "Nada para ver aqui até o momento"
        }
    }
};

export default translations;